import isEqual from 'lodash-es/isEqual';
import pick from 'lodash-es/pick';

import type { ExplicitToolConfig } from '@magicschool/business-logic/tools';
import type { Category, InsertToolConfig, Json, Tool } from '@magicschool/supabase/types';
import type { CreateToolConfigResponse } from 'app/api/admin/tool_configs/route';
import type { AdminToolResponse, AdminUpdateToolRequest } from 'app/api/admin/tools/[slug]/route';
import type { FormElementProps, SelectProps } from 'components/FormElements/types';
import { type ComponentKey, componentMapKeys } from 'components/FormElements/util';
import { emptyModel, emptyTool, emptyToolConfig } from 'features/backend-api/types';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import { toast } from 'react-hot-toast';
import { getBadgesFromPlainText } from './utils';

type EditToolErrorMap = {
  title?: string;
  slug?: string;
  prompt?: string;
  system_message?: string;
  model?: string;
  fields?: string;
  displaySection?: string;
  instructionsTab?: string;
  llmOptionsTab?: string;
  fieldsTab?: string;
};

export type EditToolStore = {
  setField: SetField<EditToolStore>;
  activeTab: string;
  toolDetails: AdminToolResponse['toolDetails'];
  pristineState: Omit<AdminToolResponse['toolDetails'], 'model'>;
  configs: ExplicitToolConfig[];
  allCategories: AdminToolResponse['allCategories'];
  allLlms: AdminToolResponse['allLlms'];
  errors: EditToolErrorMap;
  updateTool: (tool: Partial<Tool>) => void;
  updateToolConfig: (toolConfig: Partial<ExplicitToolConfig>) => void;
  updateConfigs: (configs: ExplicitToolConfig[]) => void;
  updateCategories: (newCategories: Category[]) => void;
  reset: () => void;
  loadTool: (slug: string) => Promise<void>;
  saveTool: ({ publishing }: { publishing: boolean }) => Promise<boolean>;
  saveToolConfig: (skipValidation?: boolean) => Promise<boolean>;
  validate: () => void;
  addNewToolConfig: (name: string, sourceToolConfig: ExplicitToolConfig) => Promise<void>;
  publishToolConfig: (toolConfig: ExplicitToolConfig) => Promise<boolean>;
  archiveToolConfig: (toolConfig: ExplicitToolConfig) => Promise<void>;
  clearToolFields: () => void;
  isToolDetailsPristine: () => boolean;
  isToolPristine: () => boolean;
  isToolConfigPristine: () => boolean;
  updatePristineState: (
    tool: AdminToolResponse['toolDetails']['tool'],
    config: ExplicitToolConfig,
    categories: AdminToolResponse['toolDetails']['categories'],
  ) => void;
};

const emptyToolDetails = (): AdminToolResponse['toolDetails'] => ({
  tool: emptyTool(),
  published_config: emptyToolConfig(),
  categories: [],
  model: emptyModel(),
});

const defaultState = {
  activeTab: '1',
  toolDetails: emptyToolDetails(),
  pristineState: {
    tool: emptyTool(),
    published_config: emptyToolConfig(),
    categories: [],
  },
  configs: [],
  allCategories: [],
  allLlms: [],
  errors: {},
};

export const createEditToolStoreSlice = createStoreSlice('EditToolStoreData', defaultState, ({ set, get, setField }) => ({
  setField,
  reset: () => set(structuredClone(defaultState)),
  updateTool: (tool: Partial<Tool>) => {
    set((s) => ({ toolDetails: { ...s.toolDetails, tool: { ...s.toolDetails.tool, ...tool } } }));
    get().validate();
  },
  updateToolConfig: (toolConfig: Partial<ExplicitToolConfig>) => {
    set((s) => {
      const newPublishedConfig = { ...s.toolDetails.published_config, ...toolConfig };
      const updates = { toolDetails: { ...s.toolDetails, published_config: newPublishedConfig }, pristineState: s.pristineState };
      if (newPublishedConfig.id !== s.pristineState.published_config.id) {
        updates.pristineState = { ...s.pristineState, published_config: structuredClone(newPublishedConfig) };
      }
      return updates;
    });
    get().validate();
  },
  updateConfigs: (configs: ExplicitToolConfig[]) => {
    set({ configs: structuredClone(configs) });
    get().validate();
  },
  updateCategories: (categories) => {
    set((s) => ({ toolDetails: { ...s.toolDetails, categories: structuredClone(categories) } }));
    get().validate();
  },
  loadTool: async (slug: string) => {
    set({ toolDetails: structuredClone(defaultState.toolDetails) });
    const response = await fetch<AdminToolResponse>(`/api/admin/tools/${slug}`);
    const data = await response.json();
    set({
      toolDetails: structuredClone(data.toolDetails),
      configs: data.configs,
      allCategories: data.allCategories,
      allLlms: data.allLlms,
    });
    get().updatePristineState(data.toolDetails.tool, data.toolDetails.published_config, data.toolDetails.categories);
    get().validate();
  },
  saveTool: async ({ publishing }) => {
    const { errors, toolDetails, pristineState } = get();
    if (errors.displaySection) {
      toast.error('Fix all form errors before saving');
      return false;
    }

    if (!toolDetails.tool.id) {
      toast.error('No Tool loaded');
      return false;
    }

    const configId = publishing ? toolDetails.published_config.id : pristineState.tool.published_config_id;
    if (!configId) {
      toast.error("Can't find config ID");
      return false;
    }

    const request: AdminUpdateToolRequest = {
      tool: toolDetails.tool,
      categories: toolDetails.categories.map((c: Category) => (typeof c === 'string' ? c : c.id)),
      published_config_id: configId,
    };
    const response = await fetch(`/api/admin/tools/${pristineState.tool.slug}`, {
      method: 'PATCH',
      body: JSON.stringify(request),
      onSuccess: ({ router }) => {
        const updatedTool = { ...toolDetails.tool, published_config_id: configId };
        set((s) => ({ toolDetails: { ...s.toolDetails, tool: updatedTool } }));
        get().updatePristineState(updatedTool, toolDetails.published_config, toolDetails.categories);
        toast.success('Tool saved');
        router.push(`/admin/tools/${toolDetails.tool.slug}`);
      },
      responseErrorHandlers: {
        conflict: async ({ response }) => {
          const data: { error: string } = await response.json();
          toast.error(`Conflict detected. ${data.error}`);
        },
      },
    });

    return response.ok;
  },
  updatePristineState: (tool, config, categories) => {
    set({
      pristineState: { tool: structuredClone(tool), published_config: structuredClone(config), categories: structuredClone(categories) },
    });
  },
  saveToolConfig: async (skipValidation = false) => {
    const { errors, toolDetails, updateConfigs, configs } = get();

    if (!toolDetails.tool.id) {
      toast.error('No Tool loaded');
      return false;
    }

    if (!skipValidation && (errors.instructionsTab || errors.llmOptionsTab || errors.fieldsTab)) {
      toast.error('Fix all form errors before saving');
      return false;
    }

    await fetch(`/api/admin/tool_configs/${toolDetails.published_config.id}`, {
      method: 'PATCH',
      body: JSON.stringify(toolDetails.published_config),
    });

    updateConfigs(configs.map((c) => (c.id === toolDetails.published_config.id ? toolDetails.published_config : c)));
    get().updatePristineState(toolDetails.tool, toolDetails.published_config, toolDetails.categories);
    return true;
  },
  validate: () => {
    const { toolDetails } = get();

    const validatePrompt = () => {
      if (!toolDetails.published_config?.prompt) return 'is required';
      const validFields = toolDetails.published_config?.fields?.map((f) => f.name);
      const includedFields = getBadgesFromPlainText(toolDetails.published_config?.prompt);
      const invalidField = includedFields.find((f) => !validFields.includes(f));
      return invalidField ? `Field "${invalidField}" is not valid` : '';
    };

    const errors: EditToolErrorMap = {
      title: toolDetails.tool.title ? '' : 'Title is required',
      slug: toolDetails.tool.slug ? '' : 'Slug is required',
      system_message: '',
      prompt: validatePrompt(),
      model: toolDetails.published_config.model_id ? '' : 'Model is required',
      fields: validateToolConfigFields(toolDetails.published_config.fields),
      displaySection: '',
      instructionsTab: '',
      llmOptionsTab: '',
      fieldsTab: '',
    };

    errors.displaySection = errors.title || errors.slug;
    errors.instructionsTab = errors.system_message || errors.prompt;
    errors.llmOptionsTab = errors.model;
    errors.fieldsTab = errors.fields;
    set({ errors });
  },
  addNewToolConfig: async (name: string, sourceToolConfig: ExplicitToolConfig) => {
    const { toolDetails, configs, updateConfigs, updateToolConfig, updatePristineState } = get();
    const payload: InsertToolConfig = {
      name,
      archived: sourceToolConfig.archived,
      fields: sourceToolConfig.fields,
      frequency_penalty: sourceToolConfig.frequency_penalty,
      max_length: sourceToolConfig.max_length,
      model_id: sourceToolConfig.model_id,
      presence_penalty: sourceToolConfig.presence_penalty,
      prompt: sourceToolConfig.prompt,
      system_message: sourceToolConfig.system_message,
      temperature: sourceToolConfig.temperature,
      top_p: sourceToolConfig.top_p,
      tool_id: toolDetails.tool.id,
    };

    await fetch<CreateToolConfigResponse>(`/api/admin/tool_configs`, {
      method: 'POST',
      body: JSON.stringify(payload),
      onSuccess: async ({ response }) => {
        const newConfig = await response.json();
        updateConfigs([...configs, newConfig]);
        updateToolConfig(newConfig);
        updatePristineState(toolDetails.tool, newConfig, toolDetails.categories);
        toast.success('Version saved');
      },
    });
  },
  publishToolConfig: async (targetConfig: ExplicitToolConfig) => {
    const { isToolPristine, configs, saveToolConfig, saveTool, updateToolConfig, updateConfigs } = get();
    if (!isToolPristine()) {
      const confirmation = confirm('The Display tab contains unsaved changes that WILL also be published. Do you want to continue?');
      if (!confirmation) return false;
    }

    const updatedToolConfig = { ...targetConfig, publish_date: new Date().toISOString() };
    updateToolConfig(updatedToolConfig);
    updateConfigs(configs.map((c) => (c.id === updatedToolConfig.id ? updatedToolConfig : c)));

    let success = await saveToolConfig();
    if (!success) return false;

    success = await saveTool({ publishing: true });
    if (!success) return false;
    toast.success('Version published');
    return true;
  },
  archiveToolConfig: async (toolConfig) => {
    const { toolDetails, configs, pristineState, updateToolConfig, updateConfigs, saveToolConfig, updatePristineState } = get();

    if (pristineState.published_config.id === toolConfig.id) {
      toast.error('You cannot archive the published version');
      return;
    }

    const updatedToolConfig = { ...toolConfig, archived: true };
    updateToolConfig(updatedToolConfig);
    await saveToolConfig(true);
    updateConfigs(configs.filter((c) => c.id !== toolConfig.id).concat(updatedToolConfig));

    if (toolConfig.id === toolDetails.published_config.id) {
      const publishedConfig = configs.find((c) => c.id === pristineState.tool.published_config_id);
      if (!publishedConfig) throw new Error("Couldn't find published config");
      updateToolConfig(publishedConfig);
      updatePristineState(toolDetails.tool, publishedConfig, toolDetails.categories);
    }
  },
  clearToolFields: () =>
    set({ toolDetails: structuredClone(defaultState.toolDetails), pristineState: structuredClone(defaultState.pristineState) }),
  isToolDetailsPristine: () => {
    const { toolDetails, pristineState } = get();
    return isEqual(pristineState, pick(toolDetails, 'tool', 'published_config', 'categories'));
  },
  isToolPristine: () => {
    const { toolDetails, pristineState } = get();
    return isEqual(pristineState.tool, toolDetails.tool) && isEqual(pristineState.categories, toolDetails.categories);
  },
  isToolConfigPristine: () => {
    const { toolDetails, pristineState } = get();
    return isEqual(pristineState.published_config, toolDetails.published_config);
  },
}));

export function validateToolConfigFields(fields?: Json) {
  if (!fields) return 'Missing fields array';
  if (!Array.isArray(fields)) return 'Fields must be an array';
  for (const field of fields as FormElementProps[]) {
    if (!field.name) return 'All fields must have a name';

    // NOTE: when a component property is not specified, the field is treated as a text field in the app
    const componentKey = (field.component ?? 'TextInput') as ComponentKey;
    const component = componentMapKeys.includes(componentKey);

    if (component === undefined) {
      return `Field "${field.name}" has invalid component. Valid components are: ${componentMapKeys.join(', ')}`;
    }

    if (field.component === 'Select') {
      const typedField = field as SelectProps;
      if (!typedField.options || !Array.isArray(typedField.options)) {
        return `Field "${field.name}" has missing or invalid options`;
      }
    }
  }
  return '';
}
