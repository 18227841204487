import { ChipMultiselect } from '@/components/ChipMultiselect/ChipMultiselect';
import { gradeLevels } from '@/features/profile/constants';
import { useStore } from '@/features/store';
import { Box } from '@magicschool/ui/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
export const Grades = () => {
  const {
    selectedGrades,
    setField
  } = useStore(({
    ProfileBuilderStoreData: s
  }) => ({
    selectedGrades: s.selectedGrades,
    setField: s.setField
  }), []);
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return <Box paddingX={isFullScreen ? 1 : 6} data-sentry-element="Box" data-sentry-component="Grades" data-sentry-source-file="Grades.tsx">
      <ChipMultiselect options={gradeLevels} selectedOptions={selectedGrades} setSelectedOptions={setField('selectedGrades')} data-sentry-element="ChipMultiselect" data-sentry-source-file="Grades.tsx" />
    </Box>;
};