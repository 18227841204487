import type { FileMetadata } from '@magicschool/business-logic/tools';
import type { ChatMessage } from 'features/chat/types';
import { getMaxRandomInt } from 'util/math';

export function makeToolInputMessage(inputs: any, userId?: string, threadId?: number, toolSlug?: string): ChatMessage {
  toolSlug = toolSlug ?? 'undefined'; // TODO: maybe make constructor receive parameters when streaming
  return {
    role: 'user',
    user_id: userId,
    assistant_thread_id: threadId,
    content: [
      {
        type: 'tool_inputs',
        toolId: toolSlug,
        inputs,
      },
    ],
  };
}

export function makeToolOutputMessage({
  output,
  model,
  tool_config_id,
  userId,
  threadId,
  toolSlug,
}: { output: string; model: string; tool_config_id: string; userId?: string; threadId?: number; toolSlug?: string }): ChatMessage {
  toolSlug = toolSlug || 'undefined';
  return {
    role: 'assistant',
    user_id: userId,
    assistant_thread_id: threadId,
    content: [
      {
        type: 'tool_output',
        toolId: toolSlug,
        output,
        model,
        tool_config_id,
      },
    ],
  };
}

export function makeBasicRequestMessage(input: string, userId?: string, threadId?: number, files?: FileMetadata[]): ChatMessage {
  return {
    role: 'user',
    id: getMaxRandomInt(),
    user_id: userId,
    assistant_thread_id: threadId,
    content: [
      {
        type: 'text',
        text: input,
        files,
      },
    ],
  };
}

export function makeBasicResponseMessage({
  output,
  tool_config_id,
  model,
  userId,
  threadId,
}: { output: string; tool_config_id: string; model: string; userId?: string; threadId?: number }): ChatMessage {
  return {
    role: 'assistant',
    user_id: userId,
    assistant_thread_id: threadId,
    content: [
      {
        type: 'text',
        text: output,
        model,
        tool_config_id,
      },
    ],
  };
}
