import type { DomainSpecificConfig } from '../types';

const config: DomainSpecificConfig = {
  domains: [
    /magicschoolai-git-.*\.vercel\.app/,
    /teacher-.*\.vercel\.app/,
    /teacher-.*-magicschoolai-vercel-app\.translate\.goog/,
    /app\.magicschooldev\.com/,
  ],
  domainType: 'school',
};

export default config;
