import { analyticsHandleUserLogout, analyticsTrack } from '@/util/amplitude';
import { updateUserProfile } from '@/util/dbClient';
import { clientSupabase } from '@/util/supabase';
import type {
  AuthProviderType,
  OrgAttribute,
  OrgToolAccessOverride,
  UpdateUser,
  UserAttribute,
  UserToolAccessOverride,
} from '@magicschool/supabase/types';
import type { MagicSchoolUser, SupabaseUser } from '@magicschool/supabase/user';
import { isLocal } from '@magicschool/utils/nextjs/metadata';
import type { AuthError, AuthOtpResponse, AuthResponse, OAuthResponse, Provider, UserResponse } from '@supabase/supabase-js';
import { edlinkLaunchUrl } from 'features/edlink/constants';
import type { InitialStateProps } from 'features/store/store';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import pick from 'lodash-es/pick';
import toast from 'react-hot-toast';
import type { IntlShape } from 'react-intl';
import { signInWithPassword } from './actions';
import { handleSignOut } from './helpers';

export type UpdateProfile = ({
  user,
  intl,
  inProfileBuilder,
}: {
  user: UpdateUser;
  intl: IntlShape;
  inProfileBuilder?: boolean;
}) => Promise<void>;

export type AuthStore = {
  session?: SupabaseUser | null;
  userData: MagicSchoolUser;
  userAttributes?: UserAttribute[];
  orgAttributes?: OrgAttribute[];
  userToolAccessOverrides?: UserToolAccessOverride[];
  orgToolAccessOverrides?: OrgToolAccessOverride[];
  setField: SetField<AuthStore>;
  signup: (email: string, password: string) => Promise<AuthResponse>;
  signin: typeof signInWithPassword;
  signinWithProvider: (providerName: AuthProviderType, next: string) => Promise<OAuthResponse>;
  signinWithMagicLink: (email: string) => Promise<AuthOtpResponse>;
  signOut: (args?: { deleted: boolean }) => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  updatePassword: (new_password: string) => Promise<AuthError | undefined>;
  updateProfile: UpdateProfile;
  updateUserEmail: (email: string | null | undefined) => Promise<UserResponse | undefined>;
  editProfileField: <K extends keyof MagicSchoolUser>(key: K, value: MagicSchoolUser[K]) => void;
};

export const emptyUser = (): MagicSchoolUser => ({
  authProvider: '',
  city: null,
  confirmed_at: '',
  country: null,
  created_at: null,
  district: null,
  edlink_id: null,
  email: null,
  email_confirmed_at: '',
  grades: null,
  id: '',
  locale: 'en-us',
  last_sign_in_at: '',
  name: null,
  org_id: null,
  plan: 1,
  profile_image: null,
  providers: [],
  role: null,
  school: null,
  state: null,
  status: 'active',
  subjects: null,
  custom_subject: null,
  subscriptionPlanName: 'FREE',
  updated_at: '',
  user_role: null,
  best_practices_completed: false,
  profile_status: 'new',
  student_friendly_name: null,
  serial_id: 0,
  deleted_reason: null,
});

type InitialData = Pick<
  InitialStateProps,
  'userData' | 'session' | 'orgAttributes' | 'userAttributes' | 'userToolAccessOverrides' | 'orgToolAccessOverrides'
>;

export const createAuthStoreSlice = ({
  session,
  userData,
  userAttributes = [],
  orgAttributes = [],
  userToolAccessOverrides = [],
  orgToolAccessOverrides = [],
}: InitialData) => {
  return createStoreSlice(
    'AuthStoreData',
    { session, userData: userData ?? emptyUser(), orgAttributes, userAttributes, userToolAccessOverrides, orgToolAccessOverrides },
    ({ get, set, setField }) => ({
      setField,
      signin: (email, password, next) => signInWithPassword(email, password, next),
      signup: async (email, password) => {
        const url = new URL(`/api/auth/${isLocal() ? 'confirm' : 'callback'}`, window.location.origin);

        const result = await clientSupabase.auth.signUp({
          email,
          password,
          options: {
            emailRedirectTo: url.toString(),
          },
        });
        return result;
      },
      signinWithProvider: async (providerName, next) => {
        let options: { scopes: string; redirectTo: string } | { redirectTo: string };
        if (providerName === 'edlink') {
          window.location.href = edlinkLaunchUrl(window.location.origin);
          return new Promise(() => null);
        }

        const url = new URL('/api/auth/confirm', window.location.origin);
        if (next) {
          url.searchParams.append('next', next);
        }

        if (providerName === 'azure') {
          options = {
            scopes: 'email',
            redirectTo: url.toString(),
          };
        } else {
          options = {
            redirectTo: url.toString(),
          };
        }

        const result = await clientSupabase.auth.signInWithOAuth({
          provider: providerName as Provider,
          options: options,
        });

        return result;
      },
      signinWithMagicLink: async (email: string) => {
        return await clientSupabase.auth.signInWithOtp({
          email,
          options: {
            emailRedirectTo: `${window.location.origin}/`,
          },
        });
      },
      signOut: async (args) => {
        const deleted = args?.deleted ?? false;
        set({ session: undefined });
        await handleSignOut({ deleted });
        analyticsHandleUserLogout();
      },
      sendPasswordResetEmail: async (email: string) => {
        const url = new URL('/auth/changepass', window.location.origin);

        await clientSupabase.auth.resetPasswordForEmail(email, {
          redirectTo: url.toString(),
        });
      },
      updatePassword: async (new_password: string) => {
        return clientSupabase.auth
          .updateUser({ password: new_password })
          .then(({ error }) => {
            if (error) {
              return error;
            }
          })
          .catch((error) => {
            return error;
          });
      },
      editProfileField: (field, value) => {
        set((s) => ({ userData: { ...s.userData, [field]: value } }));
      },
      updateProfile: async ({ user, intl, inProfileBuilder }) => {
        if (!user.id) return;

        // If email changed let them know to click the confirmation links
        // Will be persisted to the database by our Supabase trigger once process is completed
        if (user.email && user.email !== get().userData.email) {
          await clientSupabase.auth.updateUser({ email: user.email });
          throw new Error('To complete this process click the confirmation links sent to your new and old email addresses');
        }

        try {
          const updatedUser = await updateUserProfile(
            user.id,
            pick(
              user,
              'city',
              'district',
              'state',
              'grades',
              'name',
              'school',
              'subjects',
              'role',
              'country',
              'custom_subject',
              'student_friendly_name',
              'best_practices_completed',
              'profile_status',
              'profile_image',
            ),
          );
          set((s) => ({ userData: { ...s.userData, ...updatedUser } }));

          // We're saving after each step of the profile builder so we don't want to toast each time that happens.
          // We also have separate analytics events just for the profile builder so this one is just unnecessary noise.
          if (!inProfileBuilder) {
            toast.success(intl.formatMessage({ id: 'profile.update.success' }));
            analyticsTrack('Profile Updated', { email: user.email });
          }
        } catch (_error: unknown) {
          toast.error(intl.formatMessage({ id: 'profile.update.error' }));
        }
      },
      updateUserEmail: async (email) => {
        if (email && email !== get().userData.email) {
          return await clientSupabase.auth.updateUser({ email });
        }
      },
    }),
  );
};
