import { type RoomWithBasics, type ToolCustomization, emptyToolCustomization, generateValidToolCustomization } from 'features/rooms/types';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import { formatDateAsReadableString } from 'util/date';
import { ImmutableList, ImmutableMap } from 'util/immutable';

import {
  type RoomDuplicateRequest,
  type RoomPostRequest,
  createNewRoom,
  duplicateRoomAndTools,
  updateRoom,
} from '@/app/(teacher)/rooms/actions';
import type { ServerActionResponse } from '@/features/server/actions/types';
import { storage } from '@/features/storage';
import type { FieldVisibility } from '@magicschool/business-logic/tools';
import type { SafeToolInfo } from '@magicschool/business-logic/tools';
import { logger } from '@magicschool/logger';
import type { RoomEditResponse } from 'app/api/rooms/[id]/edit/route';
import type { RoomNewResponse } from 'app/api/rooms/new/route';
import type { CreateToolCustomizationRequest, CreateToolCustomizationResponse } from 'app/api/tool_customizations/route';
import type { FormBuilderValues } from 'components/FormElements/FormBuilder';
import type { FormElementProps } from 'components/FormElements/types';
import isEqual from 'lodash-es/isEqual';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import toast from 'react-hot-toast';
import type { IntlShape } from 'react-intl';
import { revalidateRoom } from './actions';

export const steps = [
  { id: 0, titleId: 'details', headerId: 'room-settings.details-header' },
  { id: 1, titleId: 'tools', headerId: 'room-settings.tools-header' },
  { id: 2, titleId: 'customize', headerId: 'room-settings.customize-header' },
];

export type SetToolCustomizationVisibility = (name: string, visibility: FieldVisibility) => void;

export type RoomSettingsStore = {
  loading: boolean;
  launching: boolean;
  allToolsMap: ImmutableMap<string, SafeToolInfo>;
  fullRoomToolsMap: ImmutableMap<string, RoomEditResponse['fullRoomToolsMap'][number][1]>;
  toolCustomizationsMap: ImmutableMap<string, ToolCustomization>;
  selectedToolIds: string[];
  maxStudents: number;
  roomName: string;
  roomId: string;
  selectedGradeLevel: string;
  currentStep: number;
  isNewRoom: boolean;
  toolToCustomize: SafeToolInfo | null;
  tempToolCustomization: ToolCustomization | null;
  toolInputHelpers: Record<string, string> | null;
  templateNameModalOpen: boolean;
  templateName: string;
  invalidStateModalOpen: boolean;
  invalidFields: ImmutableList<FormElementProps>;
  toolCustomizationTemplatesMap: ImmutableMap<string, ImmutableList<ToolCustomization>>;
  cancelModalOpen: boolean;
  customizingStep: 'edit' | 'test';
  toolCustomizationToDelete: SafeToolInfo | null;
  deleteModalOpen: boolean;
  requireLogin: boolean;
  searchTerm: string;
  selectedCategory: string;

  addTool: (tool: SafeToolInfo) => void;
  removeTool: (tool: SafeToolInfo) => void;
  submit: (router: AppRouterInstance, intl: IntlShape) => void;
  duplicateRoom: (newRoom: RoomDuplicateRequest, intl: IntlShape) => void;
  load: (defaultText: string, roomId?: string) => void;
  setAllTools: (select: boolean) => void;
  startToolCustomization: (tool: SafeToolInfo) => void;
  setToolCustomizationVisibility: SetToolCustomizationVisibility;
  updateCustomizedTool: (values: FormBuilderValues) => void;
  saveCustomToolTitle: (title: string) => void;
  saveCustomToolDescription: (description: string) => void;
  saveCustomizedTool: () => void;
  cancelCustomizeTool: () => void;
  deleteCustomization: () => void;
  deleteToolTemplate: (tc: ToolCustomization) => Promise<void>;
  addToolTemplate: () => Promise<void>;
  selectToolTemplate: (selected: ToolCustomization) => void;
  discardToolChanges: () => void;
  openTemplateNameModal: () => void;
  resetCustomization: () => void;
  validateFields: (tool: SafeToolInfo, customization: ToolCustomization) => boolean;
  setField: SetField<RoomSettingsStore>;
};

const patchToolCustomizationTemplates = (templates: ToolCustomization[], toolsMap: ImmutableMap<string, SafeToolInfo>) =>
  ImmutableList(templates.map((t) => generateValidToolCustomization(toolsMap?.get(t.tool_uuid, { fields: [] }).fields, t))).groupBy(
    (tc) => tc.tool_uuid,
  );

const defaultState = {
  launching: false,
  loading: true,
  allToolsMap: ImmutableMap<string, SafeToolInfo>(),
  fullRoomToolsMap: ImmutableMap<string, RoomEditResponse['fullRoomToolsMap'][number][1]>(),
  toolCustomizationsMap: ImmutableMap<string, ToolCustomization>(),
  selectedToolIds: [],
  searchTerm: '',
  selectedCategory: '',
  roomName: '',
  roomId: '',
  selectedGradeLevel: 'pre-k',
  requireLogin: false,
  currentStep: 0,
  isNewRoom: true,
  toolToCustomize: null,
  tempToolCustomization: null,
  toolInputHelpers: null,
  customizedTools: [],
  templateNameModalOpen: false,
  templateName: '',
  toolCustomizationTemplatesMap: ImmutableMap<string, ImmutableList<ToolCustomization>>(),
  invalidStateModalOpen: false,
  invalidFields: ImmutableList<FormElementProps>(),
  cancelModalOpen: false,
  customizingStep: 'edit' as const,
  toolCustomizationToDelete: null,
  deleteModalOpen: false,
  maxStudents: 250,
};

const unsetTitleDescIfUnchanged = (toolCustomization: ToolCustomization, cleanTool: SafeToolInfo): ToolCustomization => {
  const tool_title =
    toolCustomization.json_config.tool_title === cleanTool.tool.title ? undefined : toolCustomization.json_config.tool_title;
  const tool_description =
    toolCustomization.json_config.tool_description === cleanTool.tool.description
      ? undefined
      : toolCustomization.json_config.tool_description;
  return {
    ...toolCustomization,
    json_config: {
      ...toolCustomization.json_config,
      tool_title,
      tool_description,
    },
  };
};

// This is temporary until tool customisation. It either goes in labels OR tools json. Field definition is in tools
// so maybe it does belong there, but also feels weird because it's not really a tool property. We can debate later...
type ToolInputHelpers = { [key: string]: { [key: string]: string } };
const helperTextMap: ToolInputHelpers = {
  'chatbot-builder-s': {
    gradeLevel: 'room-settings.customized-tools.helper-text.grade-level',
    // this doesn't actually exist but shows how we would do tool -> input helpers
    // instructions: 'room-settings.customized-tools.helper-text.instructions',
  },
  'summary-writer-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'rewrite-it-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'email-writer-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'skit-creator-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'rap-battle-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'debate-partner-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'idea-generator-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'informational-text-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'multiple-explanations-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'make-it-relevant-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'conceptual-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'thank-you-note-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'social-stories-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'data-collection-table-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'study-habits-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'book-suggestions-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'exemplar-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'translate-it-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'joke-creator-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'content-creator-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'email-responder-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'math-review-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'youtube-summarizer-s': {}, // there is no gradeLevel in this tool, probably others. it doesn't hurt anything either way for now.
  'proofreader-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'sat-reading-practice-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'sentence-starters-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'real-world-connections-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'character-chatbot-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'custom-chatbot-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'coding-assistant-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'idea-expander-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'quiz-me-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'study-bot-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'tutor-me-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'research-assistant-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  '5-questions-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'song-generator-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'counselor-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'literary-devices-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'step-by-step-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'writing-feedback-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'language-learning-tutor-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
  'raina-for-s': { gradeLevel: 'room-settings.customized-tools.helper-text.grade-level' },
};

export const createRoomSettingsStoreSlice = createStoreSlice('RoomSettingsStoreData', defaultState, ({ set, get, getFull, setField }) => ({
  setField,
  setAllTools: (select) => set({ selectedToolIds: select ? get().allToolsMap.keySeq().toArray() : [] }),
  addTool: (td) => set((state) => ({ selectedToolIds: [...state.selectedToolIds, td.tool.id] })),
  removeTool: (td) => set((state) => ({ selectedToolIds: state.selectedToolIds.filter((t) => t !== td.tool.id) })),
  startToolCustomization: (td) => {
    const { toolCustomizationsMap, selectedGradeLevel } = get();
    const existingCustomization = toolCustomizationsMap.get(td.tool.id);
    const tempToolCustomization = existingCustomization ?? emptyToolCustomization(td, selectedGradeLevel);

    set({
      customizingStep: 'edit',
      toolToCustomize: td,
      tempToolCustomization: tempToolCustomization,
      toolInputHelpers: helperTextMap[td.tool.id],
    });
  },
  saveCustomToolTitle: (title) => {
    const { toolToCustomize, tempToolCustomization } = get();
    if (!toolToCustomize || !tempToolCustomization) return;

    const clone = structuredClone(tempToolCustomization);
    clone.json_config.tool_title = title;
    set({ tempToolCustomization: clone });
  },
  saveCustomToolDescription: (description) => {
    const { toolToCustomize, tempToolCustomization } = get();
    if (!toolToCustomize || !tempToolCustomization) return;

    const clone = structuredClone(tempToolCustomization);
    clone.json_config.tool_description = description;
    set({ tempToolCustomization: clone });
  },
  setToolCustomizationVisibility: (name, value) => {
    const { tempToolCustomization, toolToCustomize, validateFields } = get();
    if (!toolToCustomize || !tempToolCustomization) return;
    const clone = structuredClone(tempToolCustomization);
    clone.json_config.inputs[name].visibility = value;
    validateFields(toolToCustomize, clone);
    set({ tempToolCustomization: clone });
  },
  updateCustomizedTool: (values) => {
    const { tempToolCustomization, toolToCustomize, validateFields } = get();
    if (!toolToCustomize || !tempToolCustomization) return;
    const clone = structuredClone(tempToolCustomization);
    const fieldNames = Object.keys(clone.json_config.inputs);
    for (const name of fieldNames) {
      clone.json_config.inputs[name].value = values[name];
    }
    validateFields(toolToCustomize, clone);
    set({ tempToolCustomization: clone });
  },
  saveCustomizedTool: () => {
    const { tempToolCustomization, toolCustomizationsMap, toolToCustomize, validateFields, selectedGradeLevel } = get();
    if (!tempToolCustomization || !toolToCustomize) return;

    const fieldsValid = validateFields(toolToCustomize, tempToolCustomization);
    if (!fieldsValid) {
      set({ invalidStateModalOpen: true });
      return;
    }

    const existingCustomization = toolCustomizationsMap.get(toolToCustomize.tool.id);
    const defaultCustomizations = emptyToolCustomization(toolToCustomize, selectedGradeLevel);
    const fieldsChangedFromDefault = !isEqual(defaultCustomizations.json_config, tempToolCustomization.json_config);
    const fieldsDirty = !existingCustomization ? false : !isEqual(existingCustomization.json_config, tempToolCustomization.json_config);

    let tempToolCustomizationsMap = toolCustomizationsMap;
    if (!fieldsChangedFromDefault) {
      // If the fields are the same as the default, remove the customization
      tempToolCustomizationsMap = toolCustomizationsMap.delete(toolToCustomize.tool.id);
    } else if (fieldsChangedFromDefault || fieldsDirty) {
      // if the fields were changed, update the customization
      tempToolCustomizationsMap = toolCustomizationsMap.update(tempToolCustomization.tool_uuid, () =>
        structuredClone(unsetTitleDescIfUnchanged(tempToolCustomization, toolToCustomize)),
      );
    }

    set({ toolToCustomize: null, tempToolCustomization: null, toolCustomizationsMap: tempToolCustomizationsMap });
  },
  cancelCustomizeTool: () => {
    const { tempToolCustomization, toolCustomizationsMap, toolToCustomize, selectedGradeLevel, discardToolChanges } = get();
    if (!tempToolCustomization || !toolToCustomize) return;

    const customization = toolCustomizationsMap.get(toolToCustomize.tool.id, emptyToolCustomization(toolToCustomize, selectedGradeLevel));
    const dirty = !isEqual(customization, tempToolCustomization);
    if (dirty) {
      set({ cancelModalOpen: true });
    } else {
      discardToolChanges();
    }
  },
  deleteCustomization: () => {
    const { toolCustomizationToDelete, toolCustomizationsMap } = get();
    if (!toolCustomizationToDelete) return;
    set({ deleteModalOpen: false, toolCustomizationsMap: toolCustomizationsMap.delete(toolCustomizationToDelete.tool.id) });
  },
  submit: async (router, intl) => {
    const { roomName, maxStudents, roomId, allToolsMap, toolCustomizationsMap, selectedToolIds, selectedGradeLevel, requireLogin } = get();

    set({ launching: true });
    const roomData: RoomPostRequest = {
      name: roomName,
      gradeLevel: selectedGradeLevel,
      maxStudents,
      requireLogin,
      tools: selectedToolIds.map((t) => {
        const td = allToolsMap.get(t);
        if (!td) throw new Error(`Tool ${t} not found`);
        const customization = toolCustomizationsMap.get(td.tool.id, null);

        return {
          tool_slug: td.tool.slug,
          tool_uuid: td.tool.id,
          tool_customization: customization,
        };
      }),
    };

    let res: ServerActionResponse<RoomWithBasics>;
    if (roomId) {
      res = await updateRoom({ ...roomData, id: roomId });
      if (res.data) {
        await revalidateRoom(roomId);
        router.push(`/rooms/${roomId}`);
      }
    } else {
      res = await createNewRoom(roomData);
      if (res.data) {
        const newRoom = res.data.room;
        router.push(`/rooms/${newRoom.id}?joinInfoModalOpen=true`);
      }
    }
    storage.setItem(`last-grade-level-selected`, selectedGradeLevel);
    if (res.error) {
      logger.error(res.error.message, { error: res.error });
      set({ launching: false });
      router.push(`/rooms`);
      toast.error(intl.formatMessage({ id: 'toast.error.generic' }), { duration: 4000 });
    }
  },
  duplicateRoom: async (newRoom, intl) => {
    set({ launching: true });
    // Append internationalized [Copy] to the room name
    newRoom.name = `[${intl.formatMessage({ id: 'copy' })}] ${newRoom.name}`;
    const res = await duplicateRoomAndTools(newRoom);
    if (res.data) {
      getFull().RoomListStoreData.addRoom(res.data);
      toast.success(intl.formatMessage({ id: 'duplicate-room.success' }), { duration: 4000 });
    } else if (res.error) {
      logger.error(res.error.message, { error: res.error });
      set({ launching: false });
      toast.error(intl.formatMessage({ id: 'duplicate-room.error' }), { duration: 4000 });
    }
  },
  load: async (defaultText, roomId) => {
    set({ ...defaultState });

    if (roomId) {
      const response = await fetch(`/api/rooms/${roomId}/edit`);
      const { room, allToolsMap: apiAllToolsMap, fullRoomToolsMap, toolCustomizationTemplates }: RoomEditResponse = await response.json();
      const allToolsMap = ImmutableMap(apiAllToolsMap);

      const selectedTools = fullRoomToolsMap.map(([_, frt]) => frt.room_tool.tool_uuid);
      const toolCustomizationsMap = fullRoomToolsMap.reduce((acc, [_, frt]) => {
        if (frt.tool_customization) {
          const toolFields = allToolsMap.get(frt.room_tool.tool_uuid, { fields: [] }).fields;
          const validCustomization = generateValidToolCustomization(toolFields, frt.tool_customization);
          return acc.set(frt.room_tool.tool_uuid, validCustomization);
        }
        return acc;
      }, ImmutableMap<string, ToolCustomization>());

      set({
        roomId,
        toolCustomizationsMap,
        selectedToolIds: selectedTools,
        allToolsMap,
        fullRoomToolsMap: ImmutableMap(fullRoomToolsMap),
        loading: false,
        isNewRoom: false,
        selectedGradeLevel: room.grade_level,
        roomName: room.name,
        maxStudents: room.max_students,
        requireLogin: room.require_login,
        toolCustomizationTemplatesMap: patchToolCustomizationTemplates(toolCustomizationTemplates, ImmutableMap(allToolsMap)),
      });
    } else {
      const response = await fetch<RoomNewResponse>(`/api/rooms/new`);
      const { toolCustomizationTemplates, allToolsMap, requireLogin } = await response.json();
      set({
        allToolsMap: ImmutableMap(allToolsMap),
        loading: false,
        isNewRoom: true,
        selectedGradeLevel: storage.getItem(`last-grade-level-selected`) || 'pre-k',
        roomName: `${defaultText} - ${formatDateAsReadableString(new Date())}`,
        toolCustomizationTemplatesMap: patchToolCustomizationTemplates(toolCustomizationTemplates, ImmutableMap(allToolsMap)),
        requireLogin,
      });
    }
  },
  addToolTemplate: async () => {
    const { tempToolCustomization, templateName, toolToCustomize, validateFields } = get();
    if (!tempToolCustomization || !toolToCustomize) return;

    const fieldsValid = validateFields(toolToCustomize, tempToolCustomization);
    if (!fieldsValid) {
      set({ invalidStateModalOpen: true });
      return;
    }

    const body: CreateToolCustomizationRequest = {
      name: templateName,
      tool_slug: tempToolCustomization.tool_slug,
      tool_uuid: tempToolCustomization.tool_uuid,
      json_config: tempToolCustomization.json_config,
    };
    const response = await fetch(`/api/tool_customizations`, { method: 'POST', body: JSON.stringify(body) });
    const data: CreateToolCustomizationResponse = await response.json();

    set((s) => ({
      templateNameModalOpen: false,
      templateName: '',
      toolCustomizationTemplatesMap: s.toolCustomizationTemplatesMap.update(tempToolCustomization.tool_uuid, ImmutableList(), (tcs) =>
        tcs.push(data),
      ),
    }));
  },
  deleteToolTemplate: async (tc) => {
    const response = await fetch(`/api/tool_customizations/${tc.id}`, { method: 'DELETE' });
    if (!response.ok) return;

    set((s) => ({
      toolCustomizationTemplatesMap: s.toolCustomizationTemplatesMap.update(tc.tool_uuid, ImmutableList(), (tcs) =>
        tcs.filter((t) => t.id !== tc.id),
      ),
    }));
  },
  discardToolChanges: () =>
    set({ toolToCustomize: null, tempToolCustomization: null, invalidStateModalOpen: false, cancelModalOpen: false }),
  selectToolTemplate: (selected) => {
    const { tempToolCustomization } = get();
    if (!tempToolCustomization) return;
    set({ tempToolCustomization: { ...tempToolCustomization, json_config: { ...selected.json_config } } });
  },
  openTemplateNameModal: () => {
    const { tempToolCustomization, toolToCustomize, validateFields } = get();
    if (!tempToolCustomization || !toolToCustomize) return;

    // We don't want to allow users to save invalid templates
    const fieldsValid = validateFields(toolToCustomize, tempToolCustomization);
    const key = fieldsValid ? 'templateNameModalOpen' : 'invalidStateModalOpen';
    set({ [key]: true });
  },
  resetCustomization: () => {
    const { toolToCustomize, selectedGradeLevel } = get();
    if (!toolToCustomize) return;
    set({ tempToolCustomization: emptyToolCustomization(toolToCustomize, selectedGradeLevel) });
  },
  validateFields: (tool, customization) => {
    const invalidFields = ImmutableList(tool.fields).filter((f) => {
      const overrides = customization.json_config.inputs[f.name];

      // If the field is required and hidden and has no value, it's invalid because the
      // students would not be able to see it or fill it out
      return Boolean(overrides.visibility === 'hidden' && f.required && !overrides.value);
    });

    set({ invalidFields });

    return invalidFields.isEmpty();
  },
}));
